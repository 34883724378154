import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset'
import ru from 'vuetify/es5/locale/ru'
import kk from '../i18n/locales/kk'
import { getLocale } from '../i18n'

Vue.use(Vuetify)

const options = {
  breakpoint: { scrollbarWidth: 12 },
  lang: {
    current: getLocale(),
    locales: { ru, kk },
  },
  theme: {
    dark: localStorage.getItem('rekassa.kz-ui-darkTheme') === null || localStorage.getItem('rekassa.kz-ui-darkTheme') === 'true',
  },
}

export default new Vuetify({
  preset,
  ...options,
})
